<script setup lang="ts">
import { defineProps, ref, computed, watch, onMounted } from 'vue'
import type { Ref } from 'vue'
import type { ApolloError } from '@apollo/client'
import { useConfirm } from "primevue/useconfirm";

import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'

import AppFrame from '@/components/AppFrame.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'

import { saveRecoveryCodes } from '@/services/pdf'
import type { RecoveryCodes } from '@/services/pdf'

import InputSwitch from 'primevue/inputswitch'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Fieldset from 'primevue/fieldset';
import Skeleton from 'primevue/skeleton'
import Divider from 'primevue/divider';
import ConfirmDialog from 'primevue/confirmdialog';
import InputGroup from '@/components/Form/InputGroup.vue';

const props = defineProps({
    userResult: {
        type: Object,
        required: true,
        default: () => ({})
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const active2Fa = ref(false)
const qrCode = ref(null)
const secret = ref('')
const uri = ref('')
const authCode = ref('')
const errorMessages: Ref<any> = ref(null)
const successEnabled = ref(false)
const recoveryCodes = ref([])
const lock = ref(false)
const password = ref('')

const confirm = useConfirm();


const prepareMutation = gql`
    mutation prepareTwoFactor {
        prepareTwoFactor {
            qr_code
            uri
            secret
        }
    }
`

const enableMutation = gql`
    mutation enableTwoFactor($code: String!) {
        enableTwoFactor(code: $code) {
            success
            recovery_codes
        }
    }
`

const regenerateMutation = gql`
    mutation regenerateRecoveryCodes {
        regenerateRecoveryCodes
    }
`

const meQuery = gql`
    query {
        me {
            first_name
            last_name
            email
            identifier
            private_key
            salt
            iv
            has_tfa_enabled
            settings {
                send_reports_pending
                send_member_joined
                send_member_invite_expired
            }
        }
    }
`

const userTwoFactor = computed(() => props.userResult?.me?.has_tfa_enabled || false)

watch(() => props.userResult, (val) => {
    active2Fa.value = userTwoFactor.value
    lock.value = userTwoFactor.value
})

const disableMutation = gql`
    mutation disableTwoFactor($password: String!) {
        disableTwoFactor(input: { password: $password })
    }
`

const {mutate: prepareTwoFactor, onDone: onDonePrepare, loading: loadingPrepare} = useMutation(prepareMutation)
const {mutate: enableTwoFactor, onDone: onDoneEnable, onError, loading: loadingEnable} = useMutation(enableMutation, {
    refetchQueries: [{ query: meQuery }],
    awaitRefetchQueries: true
})
const {mutate: regenerateRecoveryCodes, onDone: onDoneRegenerate, loading: loadingRegenerate} = useMutation(regenerateMutation, {
    refetchQueries: [{ query: meQuery }],
    awaitRefetchQueries: true
})
const {mutate: disableTwoFactor, onDone: onDoneDisable, onError: onErrorDisable, loading: loadingDisable} = useMutation(disableMutation, {
    refetchQueries: [{ query: meQuery }],
    awaitRefetchQueries: true
})

onDonePrepare(result => {
    const { data } = result
    qrCode.value = data.prepareTwoFactor.qr_code
    secret.value = data.prepareTwoFactor.secret
    uri.value = data.prepareTwoFactor.uri
    console.log(data.prepareTwoFactor.qr_code)
})

onDoneEnable(result => {
    const { data: { enableTwoFactor } } = result
    successEnabled.value = enableTwoFactor.success
    recoveryCodes.value = enableTwoFactor.recovery_codes
    if (enableTwoFactor.success) {
        lock.value = true
    }
})

onDoneRegenerate(result => {
    const { data } = result
    successEnabled.value = true
    recoveryCodes.value = data.regenerateRecoveryCodes
})

onDoneDisable(result => {
    const { data } = result

    if (data.disableTwoFactor) {
        active2Fa.value = false
        successEnabled.value = false
        lock.value = false
        qrCode.value = null
    }
})

onError(error => {
    errorMessages.value = error
})

onErrorDisable(error => {
    errorMessages.value = error
})

const saveRecovery = async () => {
    const pdfTemplate = '/img/2fa_recovery.pdf'
    const codes = recoveryCodes.value
    const pdfBytes = await saveRecoveryCodes(pdfTemplate, { codes })
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}))
    a.download = `compentum-2fa-recovery-codes.pdf`
    a.click()
}

const update2Fa = () => {
    if (!active2Fa.value) prepareTwoFactor()
}

const enable2Fa = () => {
    enableTwoFactor({ code: authCode.value })
}

const regenerate = () => {
    regenerateRecoveryCodes()
}

const confirmRegenerate = () => {
    confirm.require({
        message: 'Wenn Sie neue Wiederherstellungs-Codes generieren, werden die alten Codes ungültig. Sind Sie sicher, dass Sie neue Codes generieren wollen?',
        header: 'Neue Wiederherstellungs-Codes generieren',
        icon: 'pi pi-exclamation-triangle',
        accept: () => regenerate()
    })
}

const disable2Fa = () => {
    disableTwoFactor({ password: password.value })
}
</script>

<template>
    <Skeleton v-if="loading" width="100%" height="23rem" />
    <AppFrame v-else :full-width="true">
        <h3 class="text-lg font-bold text-gray-600 mb-4">Zwei-Faktor-Authentifizierung</h3>
        <ValidationMessage v-if="errorMessages" :response="errorMessages" />
        <div class="lg:flex" v-if="props.userResult?.me?.email_verified_at">
            <div class="lg:w-1/2 lg:pr-6 mb-8 lg:mb-0">
                <div class="flex items-center mt-2 mb-5">
                    <InputSwitch v-model="active2Fa" :disabled="lock" class="shrink-0" @click="() => update2Fa()" />
                    <span class="ml-3">{{active2Fa ? 'Aktiv' : 'Inaktiv'}}</span>
                </div>
                <Skeleton v-if="loadingPrepare" width="100%" height="22rem" />
                <Fieldset v-if="userTwoFactor" legend="Authenticator App" class="mb-3">
                    <div class="flex gap-2 flex-wrap">
                        <InputGroup label="Passwort" class="w-full">
                            <InputText v-model="password" type="password" placeholder="Passwort" class="pr-3" />
                        </InputGroup>
                        <Button class="" :loading="loadingDisable" @click="disable2Fa"
                            :disabled="password === ''">Zwei-Faktor-Authentifizierung
                            entfernen</Button>
                    </div>
                </Fieldset>
                <Fieldset v-if="!loadingPrepare && active2Fa && qrCode && !successEnabled" legend="Authenticator App"
                    class="mb-3">
                    <div class="flex flex-col">
                        <div class="qr_code" v-html="qrCode"></div>
                        <p>Falls Sie Probleme beim Scannen des QR Codes haben, können Sie den Schlüssel hier manuell
                            kopieren:</p>
                        <pre class="p-4 border border-slate-300 my-3">{{ secret }}</pre>
                        <Divider />
                    </div>
                    <div>
                        <InputText v-model="authCode" placeholder="Code" class="pr-3 mr-4" />
                        <Button :loading="loadingEnable" type="submit" label="Speichern" @click="enable2Fa"
                            class="pl-4" />
                    </div>
                </Fieldset>
                <Fieldset v-if="successEnabled || userTwoFactor" legend="Wiederherstellungsschlüssel">
                    <div v-if="successEnabled">
                        <p>Zwei-Faktor-Authentifizierung erfolgreich eingerichtet. Bitte speichern Sie Ihren
                            Wiederherstellungsschlüssel.</p>
                        <ul class="my-3">
                            <li v-for="code in recoveryCodes">{{ code }}</li>
                        </ul>
                        <Button @click="saveRecovery">Wiederherstellungsschlüssel herunterladen</Button>
                    </div>
                    <div v-else>
                        <p class="mb-3">Hier können Sie neue Wiederherstellungsschlüssel generieren, falls Sie die alten
                            Schlüssel verlegt haben. Die alten Wiederherstellungsschlüssel werden dabei ungültig.</p>
                        <Button @click="confirmRegenerate">Neue Wiederherstellungsschlüssel generieren</Button>
                    </div>
                </Fieldset>
            </div>
            <div class="lg:w-1/2 lg:pl-4 prose prose-sm">
                <h3>Zwei-Faktor-Authentifizierung</h3>
                <p>Die Zwei-Faktor-Authentifizierung fügt Ihrem Konto eine zusätzliche Sicherheitsebene hinzu, indem sie
                    mehr als nur ein Passwort für die Anmeldung verlangt. <a
                        href="https://compentum.de/docs/hilfe/pers%C3%B6nliche-einstellungen.html#zwei-faktor-authentifizierung">Erfahren
                        Sie mehr über Zwei-Faktor-Authentifizierung.</a></p>

                <h3>Anleitung für die Einrichtung</h3>
                <h4>1. Lade eine Authentifizierungs-App herunter</h4>
                <p>Wir empfehlen dir, Duo Mobile oder Google Authenticator herunterzuladen, falls du noch keine der Apps
                    installiert hast.</p>
                <h4>2. Scanne den Barcode/QR-Code oder kopiere den Schlüssel</h4>
                <p>Scanne den Barcode/QR-Code mit der Authentifizierungs-App oder kopiere den Schlüssel und füge ihn in
                    der Authentifizierungs-App ein.</p>
                <h4>3. Kopiere den 6-stelligen Code und füge ihn ein</h4>
                <p>Nachdem der Barcode/QR-Code gescannt oder der Schlüssel eingegeben wurde, generiert deine
                    Authentifizierungs-App einen 6-stelligen Code. Kopiere den Code und kehre dann zu compentum zurück,
                    um ihn einzugeben.</p>

                <h3>Wiederherstellungsschlüssel</h3>
                <p>Diese Schlüssel dienen als Backup-Methode, um sich in einem Konto anzumelden, wenn der
                    Haupt-2FA-Mechanismus nicht verfügbar ist.</p>
                <p>Man braucht sie, um sicherzustellen, dass man auch dann auf sein Konto zugreifen kann, wenn das
                    2FA-Gerät verloren geht, gestohlen wird oder kaputt geht.</p>
                <p>Bitte laden Sie Ihre Wiederherstellungsschlüssel herunter und speichern Sie diese an einem sicheren
                    Ort.</p>

                <h3>Wiederherstellungsschlüssel erneuern</h3>
                <p>Wenn Sie Ihre Wiederherstellungsschlüssel verlegt haben, können Sie neue Wiederherstellungsschlüssel
                    generieren. Die alten Wiederherstellungsschlüssel werden dabei ungültig.</p>
            </div>
        </div>
        <div v-else>
            <p class="text-red-600">Bestätigen Sie zuerst Ihre E-Mail-Adresse. Danach können Sie die
                Zwei-Faktor-Authentifizierung einrichten</p>
        </div>
        <ConfirmDialog class="max-w-[500px]"></ConfirmDialog>
    </AppFrame>
</template>

<style scoped>
.qr_code >>> svg {
    @apply mx-auto max-w-[350px];
}

</style>
