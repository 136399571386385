<script setup lang="ts">
import AppFrame from '@/components/AppFrame.vue'
import AuthUpdatePassword from '@/containers/Auth/AuthUpdatePassword.vue'
import MailSettings from '@/containers/User/MailSettings.vue';
import UpdateUserDetails from '@/containers/User/UpdateUserDetails.vue'
import UpdateEmailAddress from '@/containers/User/UpdateEmailAddress.vue'
import UserDeleteAccount from '@/containers/User/UserDeleteAccount.vue'
import SecondFactorAuth from '@/containers/User/SecondFactorAuth.vue'
import ExportData from '@/containers/Export/ExportData.vue'

import gql from 'graphql-tag'
import { useLazyQuery } from "@vue/apollo-composable"

const meQuery = gql`
    query {
        me {
            first_name
            last_name
            email
            email_verified_at
            identifier
            private_key
            salt
            iv
            has_tfa_enabled
            settings {
                send_reports_pending
                send_member_joined
                send_member_invite_expired
                send_report_created
                send_report_updated
            }
        }
    }
`

const { result: userResult, loading, load: loadUser } = useLazyQuery(meQuery)

!userResult.value && loadUser()
</script>

<template>
    <AppFrame title="Persönliche Einstellungen" :boxed="false">
        <UpdateUserDetails :user-result="userResult" />
        <UpdateEmailAddress :user-result="userResult" />
        <AuthUpdatePassword :user-result="userResult" />
        <SecondFactorAuth :user-result="userResult" :loading="loading" />
        <MailSettings :user-result="userResult" />
        <ExportData :user-result="userResult" />
        <UserDeleteAccount :user-result="userResult" />
    </AppFrame>
</template>
